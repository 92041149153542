import React from "react";
import {LEGAL_WARNING_DOC_URL} from "Config/Router/Routes";

interface Props{
    underlined?: boolean
}

export const LegalWarningLink : React.FC<Props> = (props) => {

    const isUnderlined = props.underlined ?? false

    return (
        <a
            href={LEGAL_WARNING_DOC_URL}
            target={"_blank"}
            className={isUnderlined ? 'text-underline' : ''}
        >Aviso Legal</a>
    )

}