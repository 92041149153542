import React, {useEffect, useRef, useState} from "react";
import {RequireAuth} from "Components/Security/RequireAuth";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {Badge, Button, Col, Form, Row} from "react-bootstrap";
import {useAppDispatch} from "hooks";
import {Link} from "react-router-dom";
import {appLoading} from "Store/loadingSlice";
import {useQuery} from "react-query";
import {Notificator} from "Services/Notificator/Notificator";
import {exportAdminInscriptions, exportValidatedBySsQuery, searchAdminInscriptions} from "Api/Query/InscriptionQuery";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {InscriptionStatusesSelect} from "Components/InscriptionOrder/InscriptionStatusSelect";
import {SelectOption} from "Model/Select/SelectOption";
import Pagination from "rc-pagination";
import {RefreshSpinIcon} from "Components/Icons/RefreshSpinIcon";
import {SortArrows} from "../Shared/Crud/SortArrows";
import {LocalStorage} from "Services/LocalStorage/LocalStorage";
import {InscriptionStatus} from "../../Services/Inscription/InscriptionStatus";

interface Props {
    showStatusFilter?: boolean,
    isSocialServiceUser?: boolean
}

export const INSCRIPTION_LIST_FORM_FILTER_SEARCH = 'admin.inscriptions_list.form.search'

export const AdminInscriptionList: React.FC<Props> = (props) => {

    const PAGE_FILTER = 'admin.inscriptions_list.page'
    const FORM_FILTER_STATUS = 'admin.inscriptions_list.form.status'
    const FORM_FILTER_INIT_DATE = 'admin.inscriptions_list.form.init_date'
    const FORM_FILTER_END_DATE = 'admin.inscriptions_list.form.end_date'
    const FORM_FILTER_INSCRIPTION_ID = 'admin.inscriptions_list.form.inscription_id'

    const localStorage = new LocalStorage()
    const showStatusFilter = props.showStatusFilter ?? true
    const isSocialServiceUser = props.isSocialServiceUser ?? false

    const [selectedInscriptions, setSelectedInscriptions] = useState<{ [key: string]: boolean }>({})
    const [inscriptions, setInscriptions] = useState<any[]>([])

    const page = useRef<number>(localStorage.get(PAGE_FILTER) ? parseInt(localStorage.get(PAGE_FILTER)) : 1)
    const [pageSize, setPageSize] = useState<number>(12)
    const [exporting, setExporting] = useState<boolean>(false)
    const [exportingValidatedBySs, setExportingValidatedBySs] = useState<boolean>(false)

    const generateSearchFiltersObject = (
        searchFilterValue: string = '',
        statusFilterValue: any = null,
        initDateFilterValue: string = '',
        endDateFilterValue: string = '',
        inscriptionIdValue : string = ''
    ) : object => {
        let searchFilters = {}

        if (searchFilterValue) {
            searchFilters = {...searchFilters, q: `*${searchFilterValue}*`}
        }
        if (inscriptionIdValue) {
            searchFilters = {...searchFilters, id: inscriptionIdValue}
        }
        if (statusFilterValue) {
            searchFilters = {...searchFilters, status: parseInt(statusFilterValue.value)}
        }
        if (initDateFilterValue) {
            searchFilters = {...searchFilters, startDate: initDateFilterValue}
        }
        if (endDateFilterValue) {
            searchFilters = {...searchFilters, endDate: endDateFilterValue}
        }

        searchFilters = {...searchFilters, size: 12}
        searchFilters = {...searchFilters, page: page.current}

        return searchFilters
    }

    // init stored filters
    const defaultSearchFilterValue = localStorage.get(INSCRIPTION_LIST_FORM_FILTER_SEARCH) ?? ''
    const defaultStatusFilterValue = (isSocialServiceUser) ? null : (localStorage.get(FORM_FILTER_STATUS) ? JSON.parse(localStorage.get(FORM_FILTER_STATUS)) : null)
    const defaultInitDateFilterValue = localStorage.get(FORM_FILTER_INIT_DATE) ?? ''
    const defaultEndDateFilterValue = localStorage.get(FORM_FILTER_END_DATE) ?? ''
    const defaultInscriptionIdValue = localStorage.get(FORM_FILTER_INSCRIPTION_ID) ?? ''

    let defaultSearchFilters = generateSearchFiltersObject(
        defaultSearchFilterValue,
        defaultStatusFilterValue,
        defaultInitDateFilterValue,
        defaultEndDateFilterValue,
        defaultInscriptionIdValue
    )
    defaultSearchFilters = {...defaultSearchFilters, size: pageSize}
    defaultSearchFilters = {...defaultSearchFilters, page: page.current}

    if(isSocialServiceUser){
        defaultSearchFilters = {...defaultSearchFilters, status: InscriptionStatus.PENDING_SOCIAL_SERVICES_APPROVEMENT}
    }

    const [sortField, setSortField] = useState<string>('id')
    const [sortDirection, setSortDirection] = useState<string>('-')
    const [searchFilters, setSearchFilters] = useState<{ [key: string]: any }>(defaultSearchFilters)
    const [searchFilterValue, setSearchFilterValue] = useState<string>(defaultSearchFilterValue)
    const [statusFilterValue, setStatusFilterValue] = useState<SelectOption | null>(defaultStatusFilterValue)
    const [initDateFilterValue, setInitDateFilterValue] = useState<string>(defaultInitDateFilterValue)
    const [endDateFilterValue, setEndDateFilterValue] = useState<string>(defaultEndDateFilterValue)
    const [inscriptionIdValue, setInscriptionIdValue] = useState<string>(defaultInscriptionIdValue)

    const [totalRecords, setTotalRecords] = useState<number>(0)
    const [currentPosition, setCurrentPosition] = useState<number>(pageSize)

    const clearFilters = () => {
        localStorage.remove(INSCRIPTION_LIST_FORM_FILTER_SEARCH)
        localStorage.remove(FORM_FILTER_STATUS)
        localStorage.remove(FORM_FILTER_INIT_DATE)
        localStorage.remove(FORM_FILTER_END_DATE)
        localStorage.remove(FORM_FILTER_INSCRIPTION_ID)
        localStorage.remove(PAGE_FILTER)
        page.current = 1
        setSearchFilterValue('')
        setStatusFilterValue(null)
        setInitDateFilterValue('')
        setEndDateFilterValue('')
        setInscriptionIdValue('')
        setSearchFilters({
            page: page.current,
            size: 12
        })
    }

    const getEditUrl = (item: any) => {
        if (props.isSocialServiceUser) {
            return ROUTE_PATHS.ADMIN_SOCIAL_SERVICES_PROFILE_INSCRIPTION_DETAIL.replace(':id', item.id)

        }

        return ROUTE_PATHS.ADMIN_INSCRIPTION_DETAIL.replace(':id', item.id)
    }

    const dispatch = useAppDispatch()

    const initCheckboxes = (sessions: any) => {
        const checkboxes: { [key: string]: boolean } = {}
        sessions.map(session => checkboxes[session.id] = false)
        return checkboxes
    }

    const query = useQuery({
        enabled: false,
        queryKey: ['adminSearchInscriptions'],
        queryFn: () => searchAdminInscriptions(searchFilters, (sortDirection + sortField)),
        onSuccess: (response: any) => {
            const responseData = response.data._result
            const resp = responseData.items
            setInscriptions(resp)
            setTotalRecords(responseData.total)
            setSelectedInscriptions(initCheckboxes(resp))

            let currentPosition = pageSize * responseData.page
            currentPosition = currentPosition > responseData.total ? responseData.total : currentPosition
            setCurrentPosition(currentPosition)

            dispatch(appLoading({value: false, text: 'Cargando'}))
        },
        onError: () => {
            Notificator.error('Se ha producido un error inesperado.')
            dispatch(appLoading({value: false, text: 'Cargando'}))
        }
    })

    const downloadCsv = (csvContent: string, filename: string) => {
        const blob = new Blob([csvContent], {type: 'text/csv'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const exportQuery = useQuery({
        enabled: false,
        queryKey: ['exportAdminInscriptions'],
        queryFn: () => exportAdminInscriptions(searchFilters, '-id'),
        onSuccess: (response: any) => {
            const responseData = response.data
            const filename = response.headers['content-language'] ?? 'inscripciones.csv'
            downloadCsv(responseData, filename)
            setExporting(false)
            dispatch(appLoading({value: false, text: 'Cargando'}))
        },
        onError: () => {
            Notificator.error('Se ha producido un error inesperado.')
            setExporting(false)
            dispatch(appLoading({value: false, text: 'Cargando'}))
        }
    })

    const exportValidatedBySs = useQuery({
        enabled: false,
        queryKey: ['exportValidatedBySs'],
        queryFn: () => exportValidatedBySsQuery(),
        onSuccess: (response: any) => {
            const responseData = response.data
            const filename = response.headers['content-language'] ?? 'inscripciones-validadas-por-servicios-sociales.csv'
            downloadCsv(responseData, filename)
            setExportingValidatedBySs(false)
            dispatch(appLoading({value: false, text: 'Cargando'}))
        },
        onError: () => {
            Notificator.error('Se ha producido un error inesperado.')
            setExportingValidatedBySs(false)
            dispatch(appLoading({value: false, text: 'Cargando'}))
        }
    })

    const onEnterPressed = (event: React.KeyboardEvent): void => {
        if (event.key !== 'Enter') {
            return
        }
        search()
    }

    const applySearchFilters = () => {
        let searchFilters = {}

        if (searchFilterValue) {
            searchFilters = {...searchFilters, q: `*${searchFilterValue}*`}
        }
        if (inscriptionIdValue) {
            searchFilters = {...searchFilters, id: inscriptionIdValue}
        }
        if (statusFilterValue) {
            searchFilters = {...searchFilters, status: parseInt(statusFilterValue.value)}
        }
        if (initDateFilterValue) {
            searchFilters = {...searchFilters, startDate: initDateFilterValue}
        }
        if (endDateFilterValue) {
            searchFilters = {...searchFilters, endDate: endDateFilterValue}
        }

        searchFilters = {...searchFilters, page: page.current}
        searchFilters = {...searchFilters, size: 12}

        setSearchFilters(searchFilters)
    }

    const search = () => {
        page.current = 1
        applySearchFilters()
    }

    const onPaginationPageChange = (selectedPage: number) => {
        page.current = selectedPage
        setSearchFilters({
            ...searchFilters,
            page: selectedPage.toString()
        })
    }

    const excelExport = () => {
        setExporting(true)
        exportQuery.refetch()
    }

    const excelExportValidatedBySocialServices = () => {
        setExportingValidatedBySs(true)
        exportValidatedBySs.refetch()
    }

    const sortBy = (field: string, direction: string = '') => {
        const newSort = direction + field
        const currentSort = sortDirection + sortField

        if(newSort === currentSort && sortDirection === direction){
            direction = direction === '-' ? '' : '-'
        }

        setSortDirection(direction)
        setSortField(field)
    }

    useEffect(() => {
        applySearchFilters()
    }, [])

    useEffect(() => {
        query.refetch()
    }, [searchFilters, sortField, sortDirection])

    useEffect(() => {
        dispatch(appLoading({value: query.isLoading, text: 'Cargando'}))
    }, [query.isLoading])

    useEffect(() => {
        dispatch(appLoading({value: query.isRefetching, text: 'Cargando'}))
    }, [query.isRefetching])

    useEffect(() => {
        localStorage.set(PAGE_FILTER, page.current)
    }, [page.current]);

    return (
        <RequireAuth>
            <DefaultContainer>

                <Col id={"mainRequestList"}>

                    <Row>
                        <Col>
                            <h1 className={"pb-4"}>Listado de solicitudes</h1>
                        </Col>
                        <Col>
                            <div className={"d-flex justify-content-end"}>

                                {
                                    isSocialServiceUser &&
                                    <Button
                                        className={"purple-btn mx-2"}
                                        onClick={excelExportValidatedBySocialServices}
                                        disabled={exportingValidatedBySs}
                                    >
                                        {exportingValidatedBySs && <><RefreshSpinIcon/>&nbsp;&nbsp;</>}
                                        <>Inscripciones validadas</>
                                    </Button>
                                }

                                <Button
                                    className={"purple-btn mx-4"}
                                    onClick={excelExport}
                                    disabled={exporting}
                                >
                                    {exporting && <><RefreshSpinIcon/>&nbsp;&nbsp;</>}
                                    <>Exportar</>
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    <div className={"row my-4"}>
                        {
                            showStatusFilter &&
                            <Col xs={12} lg={3}>
                                <Form.Group controlId={"statusFilter"}>
                                    <Form.Label>
                                        Estado de la solicitud
                                    </Form.Label>
                                    <InscriptionStatusesSelect
                                        onChange={(option: any) => {
                                            localStorage.set(FORM_FILTER_STATUS, JSON.stringify(option))
                                            setStatusFilterValue(option)
                                        }
                                        }
                                        value={statusFilterValue}
                                    />

                                </Form.Group>
                            </Col>
                        }
                        <Col xs={12} lg={3}>
                            <Form.Group controlId={"initDateFilter"}>
                                <Form.Label>
                                    Fecha desde
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        localStorage.set(FORM_FILTER_INIT_DATE, e.target.value)
                                        setInitDateFilterValue(e.target.value)
                                    }
                                    }
                                    value={initDateFilterValue}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={3}>
                            <Form.Group controlId={"endDateFilter"}>
                                <Form.Label>
                                    Fecha hasta
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        localStorage.set(FORM_FILTER_END_DATE, e.target.value)
                                        setEndDateFilterValue(e.target.value)
                                    }
                                    }
                                    value={endDateFilterValue}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={3}>
                            <Form.Group controlId={"queryFilter"}>
                                <Form.Label>
                                    Nº Solicitud
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={(e: any) => {
                                        localStorage.set(FORM_FILTER_INSCRIPTION_ID, e.target.value)
                                        setInscriptionIdValue(e.target.value)
                                    }}
                                    onKeyDown={onEnterPressed}
                                    value={inscriptionIdValue}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={4} className={"mt-lg-3"}>
                            <Form.Group controlId={"queryFilter"}>
                                <Form.Label>
                                    Buscar
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={(e: any) => {
                                        localStorage.set(INSCRIPTION_LIST_FORM_FILTER_SEARCH, e.target.value)
                                        setSearchFilterValue(e.target.value)
                                    }
                                    }
                                    onKeyDown={onEnterPressed}
                                    value={searchFilterValue}
                                />
                            </Form.Group>
                        </Col>
                    </div>
                    <div className={"row my-4"}>
                        <Col className={"d-flex align-items-end"}>
                            <Button
                                className={"purple-btn"}
                                onClick={search}
                            >
                                Buscar
                            </Button>
                            &nbsp;
                            <Button
                                className={"white-btn"}
                                onClick={clearFilters}
                            >
                                Limpiar filtros
                            </Button>
                        </Col>
                    </div>
                    <br/>

                    <p>{currentPosition} de {totalRecords} solicitudes</p>
                    <table className={"table table-bordered redTable"}>
                        <thead>
                        <tr>
                            <th key={1}>
                                <Link onClick={() => sortBy('id')} to={"#"}>SOLICITUD</Link>
                                <SortArrows field={'id'} sortField={sortField} sortDirection={sortDirection}/>
                            </th>
                            <th key={2}>
                                <Link onClick={() => sortBy('createdAt')} to={"#"}>FECHA</Link>
                                <SortArrows field={'createdAt'} sortField={sortField} sortDirection={sortDirection}/>
                            </th>
                            <th key={3}>
                                <Link onClick={() => sortBy('status')} to={"#"}>ESTADO</Link>
                                <SortArrows field={'status'} sortField={sortField} sortDirection={sortDirection}/>
                            </th>
                            {
                                !isSocialServiceUser &&
                                <th key={4}>
                                    <Link onClick={() => sortBy('totalWithDiscount')} to={"#"}>TOTAL</Link>
                                    <SortArrows field={'totalWithDiscount'} sortField={sortField}
                                                sortDirection={sortDirection}/>
                                </th>
                            }
                            <th key={5}>ACCIONES</th>
                        </tr>
                        </thead>
                        <tbody>

                        {
                            inscriptions.length === 0 && <tr>
                                <td colSpan={5}>No hay inscripciones pendientes.</td>
                            </tr>
                        }

                        {inscriptions.map((item, key) => <tr key={key}>
                            <>
                                {/*<td>*/}
                                {/*    <Form.Check type="checkbox"/>*/}
                                {/*</td>*/}
                                <td>
                                    <Link to={getEditUrl(item)}>#{item.id} - {item.name}</Link>
                                </td>
                                <td>
                                    {item.createdAt}
                                </td>
                                <td>
                                    <Badge bg={item.status.color}>{item.status.name.toUpperCase()}</Badge>
                                </td>

                                {
                                    !isSocialServiceUser &&
                                    <td>
                                        {item.finalPrice} €
                                    </td>
                                }
                                <td>
                                    <Link
                                        className={"purple-btn btn-sm"}
                                        to={getEditUrl(item)}
                                    >
                                        Modificar
                                    </Link>
                                </td>
                            </>
                        </tr>)}
                        </tbody>
                    </table>

                    <Row>
                        <Col>
                            {totalRecords > 0 &&
                                <Pagination
                                    total={totalRecords}
                                    pageSize={pageSize}
                                    current={page.current}
                                    onChange={onPaginationPageChange}
                                />
                            }
                        </Col>
                    </Row>

                </Col>
            </DefaultContainer>
        </RequireAuth>
    )
}
