import React, {useEffect, useRef, useState} from "react";
import {RequireAuth} from "Components/Security/RequireAuth";
import {Badge, Button, Col, Form, Row} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {AdminCrudList} from "Views/Shared/Crud/AdminCrudList";
import {getUsers} from "Api/Query/UserQuery";
import {deleteUsers, impersonateUser, sendUserActivationMail} from "Api/Mutation/UserMutation";
import {useMutation} from "react-query";
import {requestPasswordRecovery} from "../../Api/Mutation/SecurityMutation";
import {Notificator} from "../../Services/Notificator/Notificator";
import {appLoading} from "../../Store/loadingSlice";
import {useDispatch} from "react-redux";
import {CookieService} from "../../Services/Http/CookieService";
import {IMPERSONATION_COOKIE_NAME} from "../../Services/User/ImpersonationService";
import Select from "react-select";
import {SelectActivityModal} from "../Activities/SelectActivityModal";
import {createActivityPrivateInscription} from "Api/Mutation/ActivityMutation";
import {LocalStorage} from "../../Services/LocalStorage/LocalStorage";

export const AdminUserList: React.FC = () => {

    const localStorage = new LocalStorage()
    const PAGE_FILTER = 'admin.users.page'
    const FORM_FILTER_SEARCH = 'admin.users.search'
    const FORM_FILTER_STATUS = 'admin.users.course'

    // init stored filters
    const defaultSearchFilterValue = localStorage.get(FORM_FILTER_SEARCH) ?? ''
    const defaultStatusFilterValue = localStorage.get(FORM_FILTER_STATUS) ? JSON.parse(localStorage.get(FORM_FILTER_STATUS)) : null
    const page = useRef<number>(localStorage.get(PAGE_FILTER) ? parseInt(localStorage.get(PAGE_FILTER)) : 1)

    const createFilterChangesObject = (
        searchQuery : string = '',
        status: any = null
    ) => {
        const parsedFilters: any = {}

        if (searchQuery) {
            parsedFilters['q'] = searchQuery
        }

        if (status) {
            parsedFilters['active'] = status.value
        }

        return parsedFilters
    }

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const cookieService = new CookieService()

    const [customFiltersData, setCustomFiltersData] = useState<{ [key: string]: any }>(
        createFilterChangesObject(
            defaultSearchFilterValue,
            defaultStatusFilterValue
        )
    )
    const [customFiltersDataToApply, setCustomFiltersDataToApply] = useState<{ [key: string]: any }>(
        createFilterChangesObject(
            defaultSearchFilterValue,
            defaultStatusFilterValue
        )
    )
    const [showPrivInscriptionModal, setShowPrivInscriptionModal] = useState<boolean>(false)
    const [selectedUserId, setSelectedUserId] = useState<string>('')
    const [status, setStatus] = useState<any>(defaultStatusFilterValue)

    const validatedFilterOptions: any = [
        {label: 'Activo', value: 1},
        {label: 'Inactivo', value: 0},
    ]

    const applyFilterChanges = () => {
        const parsedFilters: any = createFilterChangesObject(
            customFiltersData.q ?? '',
            typeof customFiltersData.active?.value !== 'undefined' ? customFiltersData.active : null
        )

        setCustomFiltersDataToApply(parsedFilters)
    }

    const headers: string[] = [
        'NOMBRE DE USUARIO',
        'TUTOR/ES',
        'ESTADO VERIFICACIÓN',
        'EMAIL',
        'ESTADO',
        'REGISTRADO EL',
        'ÚLTIMO LOGIN',
        'ACCIONES'
    ]

    const requestMutation = useMutation({
        mutationKey: ['requestPasswordRecoveryAdminMutation'],
        mutationFn: (email: string) => requestPasswordRecovery(email),
        onSuccess: (response: any) => {
            Notificator.success('Se ha enviado un email de recuperación al usuario.')
        },
        onError: (error: any) => {
            if (error.response.status === 404) {
                Notificator.error('El email introducido no existe en nuestro sistema.')
            } else {
                Notificator.error('Se ha producido un error al recuperar la contraseña')
            }
        }
    })

    const impersonateMutation = useMutation({
        mutationKey: ['impersonateMutation'],
        mutationFn: (userId: string) => impersonateUser(userId),
        onSuccess: (response: any) => {
            const data = response.data._result
            cookieService.setCookie(IMPERSONATION_COOKIE_NAME, JSON.stringify(data), 1)
            Notificator.success('Te has impersonado correctamente')
            window.location.href = ROUTE_PATHS.DASHBOARD
        },
        onError: () => {
            Notificator.error('Error al impersonar el usuario')
        }
    })

    const activateMutation = useMutation({
        mutationKey: ['activateMutation'],
        mutationFn: (userId: string) => sendUserActivationMail(userId),
        onSuccess: () => {
            Notificator.success('El email se ha enviado correctamente')
        },
        onError: (e: any) => {
            const error = e.response?.data._error.message ?? 'Se ha producido un error'
            console.log(error)
            Notificator.error(error)
        }
    })

    const sendActivityPrivateInscriptionMutation = useMutation({
        mutationKey: ['sendActivityPrivateInscription'],
        mutationFn: (data: {
            userId: string,
            activityId: string
        }) => createActivityPrivateInscription(data.userId, data.activityId),
        onSuccess: () => {
            Notificator.success('El email con el enalce a la actividad se ha enviado correctamente');
            setShowPrivInscriptionModal(false);
        },
        onError: (e: any) => {
            const error = e.response?.data._error.message ?? 'Se ha producido un error'
            Notificator.error(error);
            setShowPrivInscriptionModal(false);
        }
    })

    const resetPassword = (email: string) => {
        if(!window.confirm('¿Deseas enviar un email de recuperación de contraseña al usuario?')){
            return
        }

        requestMutation.mutate(email)
    }

    const impersonate = (userId: string) => {
        if(!window.confirm('¿Estas seguro que deseas impersonarte como este usuario?')){
            return
        }

        impersonateMutation.mutate(userId)
    }

    const activate = (userId: string) => {
        if(!window.confirm('¿Deseas enviar un email de activación a este usuario?')){
            return
        }

        activateMutation.mutate(userId)
    }

    const showActivityPrivateInscriptionModal = (userId: string) => {
        setSelectedUserId(userId)
        setShowPrivInscriptionModal(true)
    }

    const sendActivityPrivateInscription = (activityId: string) => {
        if(!activityId?.length){
            console.log('no selected')
            return
        }

        sendActivityPrivateInscriptionMutation.mutate({
            userId: selectedUserId,
            activityId: activityId
        });
    }

    useEffect(() => {
        dispatch(
            appLoading({
                value: impersonateMutation.isLoading,
                text: 'Cargando'
            })
        )
    }, [impersonateMutation.isLoading])

    useEffect(() => {
        dispatch(
            appLoading({
                value: sendActivityPrivateInscriptionMutation.isLoading,
                text: 'Procesando ...'
            })
        )
    }, [sendActivityPrivateInscriptionMutation.isLoading])

    useEffect(() => {
        localStorage.set(PAGE_FILTER, page.current)
    }, [page.current]);

    return (
        <RequireAuth>

            <AdminCrudList
                defaultPage={page.current}
                onPageChange={(newPage:number) => {
                    page.current = newPage
                }}
                id={"adminUsersList"}
                title={"Listado de usuarios"}
                headers={headers}
                queryMethod={getUsers}
                queryName={"adminListUsers"}
                deleteMethod={deleteUsers}
                tableRow={(item: any, key: number) =>
                    <>
                        <td className={"align-middle"}>
                            <Link to={ROUTE_PATHS.ADMIN_USER_EDIT.replace(':id', item.id)}>
                                {item.username}
                            </Link>
                        </td>
                        <td className={"align-middle"}>
                            <ul className={"py-0 my-0"}>
                                {item.tutor.name && <>
                                    <li>{item.tutor.name} {item.tutor.surnames}</li>
                                </>
                                }
                                {item.tutor2.name && <>
                                    <li>{item.tutor2.name} {item.tutor2.surnames}</li>
                                </>}
                            </ul>

                        </td>
                        <td className={"align-middle"}>
                            {
                                item.isEmailValidated ?
                                    <div className={"text-center"}><Badge bg={"success"}>Validado</Badge></div> :
                                    <div className={"text-center"}><Badge bg={"danger"}>Sin validar</Badge></div>
                            }
                        </td>
                        <td className={"align-middle"}>{item.email}</td>
                        <td className={"align-middle"}>
                            {
                                item.isActive ?
                                    <span className={"text-success"}><strong>Activo</strong></span> :
                                    <span className={"text-danger"}><strong>Inactivo</strong></span>
                            }
                        </td>
                        <td className={"align-middle"}>{item.createdAt}</td>
                        <td className={"align-middle"}>{item.lastLogin}</td>
                        <td className={"text-center align-middle"}>
                            <Button
                                className={"purple-btn btn-sm mb-1 d-block w-100"}
                                onClick={() => navigate(ROUTE_PATHS.ADMIN_USER_EDIT.replace(":id", item.id))}
                            >
                                Modificar
                            </Button>

                            {/*&nbsp;*/}
                            {/*<Button*/}
                            {/*    className={"btn btn-warning btn-sm mb-1"}*/}
                            {/*>*/}
                            {/*    Impersonar*/}
                            {/*</Button>*/}

                            <Button
                                className={"btn btn-success btn-sm d-block w-100 mt-2"}
                                onClick={() => resetPassword(item.email)}
                            >
                                Restablecer contraseña
                            </Button>

                            <Button
                                className={"btn btn-dark btn-sm d-block w-100 mt-2"}
                                onClick={() => impersonate(item.id)}
                            >
                                Impersonar
                            </Button>

                            <Button
                                className={"btn btn-light border-dark btn-sm d-block w-100 mt-2"}
                                onClick={() => showActivityPrivateInscriptionModal(item.id)}
                            >
                                Enviar enlace actividad
                            </Button>

                            {
                                !item.isEmailValidated && <>
                                    <Button
                                        className={"btn btn-secondary btn-sm d-block w-100 mt-2"}
                                        onClick={() => activate(item.id)}
                                    >
                                        Activar
                                    </Button>
                                </>
                            }
                        </td>
                    </>
                }
                createButton={
                    <Button
                        className={"purple-btn"}
                        href={ROUTE_PATHS.ADMIN_USER_CREATED}
                    >
                        Añadir usuario
                    </Button>
                }
                customFiltersData={customFiltersDataToApply}
                customFilters={
                    <>
                        <Col xs={12} sm={6}>
                            <Form.Group controlId={"queryFilter"}>
                                <Form.Label>
                                    Buscar
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={(e: any) => {
                                        const value = e.target.value ?? ''
                                        setCustomFiltersData({...customFiltersData, q: value})
                                        localStorage.set(FORM_FILTER_SEARCH, value)
                                    }}
                                    value={customFiltersData.q ?? ''}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={6} md={3}>
                            <Form.Group controlId={"queryFilter"}>
                                <Form.Label>
                                    Estado
                                </Form.Label>
                                <Select
                                    placeholder={"Selecciona una opción"}
                                    options={validatedFilterOptions}
                                    value={status ?? ''}
                                    isClearable={true}
                                    onChange={(e: any) => {
                                        if (e && e.value !== null) {
                                            setStatus(e)
                                            setCustomFiltersData({...customFiltersData, active: e})
                                            localStorage.set(FORM_FILTER_STATUS, JSON.stringify(e))
                                        } else {
                                            setStatus(null)
                                            setCustomFiltersData({...customFiltersData, active: null})
                                            localStorage.set(FORM_FILTER_STATUS, null)
                                        }
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Row className={"mt-2"}>
                            <Col>
                                <Button
                                    className={"purple-btn"}
                                    onClick={applyFilterChanges}
                                >Buscar</Button>
                                &nbsp;
                                <Button
                                    variant={"secondary"}
                                    onClick={() => {
                                        setCustomFiltersData({})
                                        setCustomFiltersDataToApply({})
                                        setStatus(null)
                                        localStorage.set(FORM_FILTER_SEARCH, '')
                                        localStorage.set(FORM_FILTER_STATUS, null)
                                    }
                                    }
                                >Limpiar filtros</Button>
                            </Col>
                        </Row>
                    </>
                }
            />

            <SelectActivityModal
                onClose={() => setShowPrivInscriptionModal(false)}
                onSubmit={(activity: string) => sendActivityPrivateInscription(activity)}
                show={showPrivInscriptionModal}
            />

        </RequireAuth>
    )
}
