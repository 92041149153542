import React, {useEffect, useState} from "react";
import {RequireAuth} from "Components/Security/RequireAuth";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {Alert, Badge, Button, Col, Form, Row} from "react-bootstrap";
import {useMutation, useQuery} from "react-query";
import {getAdminInscription, getInscriptionObservations} from "Api/Query/InscriptionQuery";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Notificator} from "Services/Notificator/Notificator";
import {CartItemSpecifications} from "Views/Cart/CartItemSpecifications";
import {appLoading} from "Store/loadingSlice";
import {useDispatch} from "react-redux";
import {INSCRIPTION_PAID_STATUS_ID} from "Model/Inscription/Inscription";
import {
    DISCOUNT_TYPE_LABELS_BY_ID,
    DISCOUNT_TYPE_ORDINARY_ID,
    DiscountTypeSelect
} from "Components/InscriptionOrder/DiscountTypeSelect";
import {InscriptionStatusesSelect} from "Components/InscriptionOrder/InscriptionStatusSelect";
import {SelectOption} from "Model/Select/SelectOption";
import {addInscriptionObservation, updateInscription} from "Api/Mutation/InscriptionMutation";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    validateInscriptionOrderActivityBySocialServiceUser
} from "Api/Mutation/InscriptionOrder";
import {MoneyAmount} from "Components/Money/MoneyAmount";
import { LocalStorage } from "Services/LocalStorage/LocalStorage";
import {INSCRIPTION_LIST_FORM_FILTER_SEARCH} from "./AdminInscriptionList";
import {Enabled} from "../../Components/Enabled/Enabled";

interface Props {
    isSocialServiceUserProfile?: boolean
}

export const AdminInscriptionDetail: React.FC<Props> = (props) => {

    const {id} = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const localStorage = new LocalStorage()

    const isSocialServiceUser = props.isSocialServiceUserProfile ?? false

    const [inscription, setInscription] = useState<any>(null)
    const [status, setStatus] = useState<any>(null)
    const [observation, setObservation] = useState<string>('')
    const [observations, setObservations] = useState<any>([])
    const [discountType, setDiscountType] = useState<any>(null)
    const [discountLabel, setDiscountLabel] = useState<string>('')
    const [socialWorker, setSocialWorker] = useState<string>('')
    const [subtotalPrice, setSubtotalPrice] = useState<number>(0)
    const [totalPriceWithAllServices, setTotalPriceWithAllServices] = useState<number>(0)
    const [amountToBePaidByBankTransfer, setAmountToBePaidByBankTransfer] = useState<number>(0)
    const [discountAmountOverPrice, setDiscountAmountOverPrice] = useState<number>(0)
    const [totalPriceWithDiscount, setTotalPriceWithDiscount] = useState<number>(0)
    const [rejectedReason, setRejectedReason] = useState<string>('')
    const [sendObservationToTutor, setSendObservationToTutor] = useState<boolean>(false)
    const [totalInscriptions, setTotalInscriptions] = useState<number>(1)
    const [tutorFullname, setTutorFullname] = useState<string>('')

    const [inscriptionOrderActivitiesToDelete, setInscriptionOrderActivitiesToDelete] = useState<any>([])

    const inscriptionQuery = useQuery({
        queryKey: ['getAdminInscription', id],
        queryFn: () => getAdminInscription(id ?? ''),
        onSuccess: async (response: any) => {
            const inscription = response.data._result
            const discountLabelValue = DISCOUNT_TYPE_LABELS_BY_ID[inscription.discount.id]
            setInscription(inscription)
            setStatus(SelectOption.fromPrimitives(inscription.status.name, inscription.status.id))
            setDiscountLabel(discountLabelValue)
            setDiscountType(SelectOption.fromPrimitives(discountLabelValue, inscription.discount.id))
            setSubtotalPrice(inscription.subtotalPrice)
            setTotalPriceWithAllServices(inscription.totalPrice)
            setAmountToBePaidByBankTransfer(inscription.subtotalPrice - inscription.totalPrice)
            setDiscountAmountOverPrice(inscription.discountAmountOverPrice)
            setTotalPriceWithDiscount(inscription.totalPriceWithDiscount)
            setRejectedReason(inscription.rejectedReason)
            setTotalInscriptions(inscription.tutor.totalInscriptions)
            setTutorFullname(inscription.tutor.name + ' ' + inscription.tutor.surnames)

            if (inscription.socialWorker) {
                setSocialWorker(inscription.socialWorker)
            }

        },
        onError: (error: any) => {
            Notificator.error('Error al obtener la solicitud', 'Error')
        }
    })

    const getObservations = useQuery({
        queryKey: ['getInscriptionObservations', id],
        queryFn: () => getInscriptionObservations(id ?? ''),
        onSuccess: (response: any) => {
            setObservations(response.data._result)
        }
    })

    const addObservationMutation = useMutation({
        mutationFn: (body: any) => addInscriptionObservation(body.inscriptionId, body.observation, body.sendMailToTutor),
        mutationKey: ['addObservationMutation'],
        onSuccess: () => {
            setObservation('')
            setSendObservationToTutor(false)
            getObservations.refetch()
            Notificator.success('La observación se ha añadido.')
        },
        onError: () => {
            Notificator.error('Se ha producido un error al añadir la observación.')
        }
    })

    const updateMutation = useMutation({
        mutationFn: (body: any) => updateInscription(body.inscriptionId, body),
        mutationKey: ['updateMutation'],
        onSuccess: () => {
            Notificator.success('La inscripción se ha actualizado correctamente.')
            inscriptionQuery.refetch()
            getObservations.refetch()
        },
        onError: () => {
            Notificator.error('Error al actualizar la inscripción.')
        }
    })

    const validateBySocialServiceMutation = useMutation({
        mutationFn: (body: {
            id: string,
            approve: boolean
        }) => validateInscriptionOrderActivityBySocialServiceUser(body.id, body.approve),
        mutationKey: ['validateInscriptionOrderActivityBySocialServiceUser'],
        onSuccess: () => {
            Notificator.success('La inscripción se ha actualizado correctamente.')
            inscriptionQuery.refetch()
            getObservations.refetch()
        },
        onError: () => {
            Notificator.error('Error al actualizar la inscripción.')
            inscriptionQuery.refetch()
            getObservations.refetch()
        }
    })

    const createObservation = () => {
        let params = {
            inscriptionId: inscription.id,
            observation: observation,
            sendMailToTutor: sendObservationToTutor
        }

        addObservationMutation.mutate(params)
    }

    const update = () => {
        const body = {
            inscriptionId: inscription.id,
            status: parseInt(status.value),
            inscriptionOrderDiscountType: discountType.value,
            inscriptionOrderActivitiesToDelete: inscriptionOrderActivitiesToDelete,
            socialWorker: socialWorker,
            rejectedReason: rejectedReason
        }
        updateMutation.mutate(body)
    }

    const deleteInscriptionOrderActivity = (inscritionOrderActivity: any) => {
        if (!window.confirm('¿Deseas eliminar la actividad de la Inscripción?')) {
            return
        }

        setSubtotalPrice(subtotalPrice - inscritionOrderActivity.totalPrice)
        setInscriptionOrderActivitiesToDelete([...inscriptionOrderActivitiesToDelete, inscritionOrderActivity.id])
    }

    const isInscriptionOrderActivityMarkedToDelete = (inscriptionOrderId: string): boolean => {
        return inscriptionOrderActivitiesToDelete.includes(inscriptionOrderId)
    }

    const validateBySocialService = (
        id: string,
        approve: boolean
    ) => {
        validateBySocialServiceMutation.mutate({
            id: id,
            approve: approve
        })
    }

    useEffect(() => {
        dispatch(appLoading({value: inscriptionQuery.isLoading, text: 'Cargando'}))
    }, [inscriptionQuery.isLoading])

    useEffect(() => {
        if (inscriptionQuery.isLoading) {
            return
        }

        dispatch(appLoading({value: addObservationMutation.isLoading, text: 'Cargando'}))
    }, [addObservationMutation.isLoading])

    useEffect(() => {
        if (inscriptionQuery.isLoading) {
            return
        }

        dispatch(appLoading({value: updateMutation.isLoading, text: 'Cargando'}))
    }, [updateMutation.isLoading])

    useEffect(() => {
        if (inscriptionQuery.isLoading) {
            return
        }

        dispatch(appLoading({value: validateBySocialServiceMutation.isLoading, text: 'Validando'}))
    }, [validateBySocialServiceMutation.isLoading])

    useEffect(() => {
        inscriptionQuery.refetch()
    }, []);

    return (
        <RequireAuth>
            <DefaultContainer>

                {!inscription && <></>}


                {inscription && inscription.inscriptionOrder &&
                    <Col id={"mainRequestDetail"}>

                        <Row>
                            <Col sm={9}>
                                <h1>{inscription.name}</h1>
                                {
                                    totalInscriptions > 1 &&
                                    <small className={"text-primary"}>
                                        <Link
                                          to={ROUTE_PATHS.ADMIN_INSCRIPTION_LIST}
                                          onClick={() => {
                                              localStorage.set(INSCRIPTION_LIST_FORM_FILTER_SEARCH, tutorFullname)
                                            }
                                          }
                                        >
                                            <strong>({totalInscriptions}) inscripciones asociadas al tutor</strong>
                                        </Link>
                                    </small>
                                }
                            </Col>
                            <Col>
                                <div className={"d-flex justify-content-end"}>
                                    <>
                                        <Button
                                            onClick={() => isSocialServiceUser ? navigate(ROUTE_PATHS.DASHBOARD) : navigate(ROUTE_PATHS.ADMIN_INSCRIPTION_LIST)}
                                            className={"purple-btn-outline"}
                                        >
                                            Volver
                                        </Button>
                                        &nbsp;
                                        {
                                            !isSocialServiceUser &&
                                            <Button
                                                className={"purple-btn"}
                                                onClick={update}
                                            >
                                                Guardar los cambios
                                            </Button>
                                        }
                                    </>

                                </div>
                            </Col>
                        </Row>

                        <Row className={"mt-4"}>
                            <Col>
                                <div className={"alert alert-client"}>
                                    <p key={1}><strong>Información del tutor:</strong></p>
                                    <p key={2}>{inscription.inscriptionOrder.tutor.name} {inscription.inscriptionOrder.tutor.surnames}</p>
                                    <p key={3}>{inscription.tutor.address}</p>
                                    <p key={4}>{inscription.tutor.email}</p>
                                    <ul>
                                        {
                                            inscription.tutor.phones?.map((phone: string, k: number) => {
                                                return <li key={k}>Telf. {phone}</li>
                                            })
                                        }
                                    </ul>
                                </div>

                                {
                                    inscription.cancelledAt &&
                                    <p className={"mt-3"}>
                                        <strong>Fecha de cancelación:</strong> {inscription.cancelledAt}
                                    </p>
                                }

                            </Col>
                            <Col>

                                <Form.Group controlId="inscriptionType">
                                    <Form.Label className={"fw-bold"}>
                                        Tipo de Inscripción / Descuentos
                                    </Form.Label>

                                    {isSocialServiceUser && <p>{discountType.label}</p>}

                                    {
                                        isSocialServiceUser && <>
                                            <Form.Label className={"fw-bold"}>
                                                Trabajador social
                                            </Form.Label>
                                            <p>{socialWorker}</p>
                                        </>
                                    }

                                    {!isSocialServiceUser &&
                                        <DiscountTypeSelect
                                            onChange={(option: any, socialWorker: string) => {
                                                setSocialWorker(socialWorker)
                                                setDiscountType(option)
                                            }}
                                            value={discountType}
                                            socialWorker={socialWorker}
                                            isSocialWorkerInvalid={!socialWorker}
                                        />
                                    }
                                </Form.Group>
                                <Form.Group controlId="inscriptionStatus" className={"mt-4"}>
                                    <Form.Label className={"fw-bold"}>
                                        Estado
                                    </Form.Label>

                                    {isSocialServiceUser && <p>{status.label}</p>}

                                    {
                                        !isSocialServiceUser &&
                                        <InscriptionStatusesSelect
                                            isAdmin={true}
                                            onChange={(option: any, rejectedReason: string) => {
                                                setStatus(option)
                                                setRejectedReason(rejectedReason)
                                            }}
                                            value={status}
                                            defaultRejectedReason={rejectedReason}
                                        />
                                    }

                                </Form.Group>
                            </Col>
                        </Row>

                        <table className={"table table-bordered redTable mt-4"}>
                            <thead>
                            <tr>
                                <th>ARTÍCULO</th>
                                {
                                    !isSocialServiceUser &&
                                    <>
                                        <th>PRECIO</th>
                                        <th>SUBTOTAL</th>
                                    </>
                                }
                            </tr>
                            </thead>
                            <tbody>

                            {
                                inscription.inscriptionOrder.inscriptionOrderActivities.map((inscriptionOrderActivity: any, key: number) => {
                                    return isInscriptionOrderActivityMarkedToDelete(inscriptionOrderActivity.id)
                                        ? null :
                                        (
                                            <tr key={key}>
                                                <td>
                                                    <CartItemSpecifications item={inscriptionOrderActivity}/>
                                                    <div className={"mt-3 mb-4"}>
                                                        <p>
                                                            <strong>Estado:</strong> &nbsp;
                                                            <Badge
                                                                bg={inscriptionOrderActivity.status.color}>{inscriptionOrderActivity.status.name.toUpperCase()}</Badge>
                                                        </p>
                                                        {
                                                            inscriptionOrderActivity.completedAt && <p>
                                                                <strong>Fecha
                                                                    completado:</strong> &nbsp;{inscriptionOrderActivity.completedAt}
                                                            </p>
                                                        }

                                                        {
                                                            inscriptionOrderActivity.canceledAt && <p>
                                                                <strong>Fecha
                                                                    cancelación:</strong> &nbsp;{inscriptionOrderActivity.canceledAt}
                                                            </p>
                                                        }
                                                    </div>

                                                    <Row className={"my-3"}>
                                                        <Col>
                                                            {
                                                                inscription.inscriptionOrder.inscriptionOrderActivities.length > 1 &&
                                                                !isSocialServiceUser &&
                                                                <Button
                                                                    className={"btn-danger btn-sm"}
                                                                    onClick={() => deleteInscriptionOrderActivity(inscriptionOrderActivity)}
                                                                >Eliminar</Button>
                                                            }

                                                            {
                                                                isSocialServiceUser &&
                                                                inscriptionOrderActivity.status.id === 3 && <>
                                                                    <Button
                                                                        variant={"success"}
                                                                        className={"btn"}
                                                                        onClick={() => validateBySocialService(inscriptionOrderActivity.id, true)}
                                                                    >
                                                                        <FontAwesomeIcon icon={faCheck} color={"white"}/>
                                                                        &nbsp;&nbsp;Aceptar
                                                                    </Button>
                                                                    &nbsp;
                                                                    <Button
                                                                        variant={"danger"}
                                                                        className={"btn"}
                                                                        onClick={() => validateBySocialService(inscriptionOrderActivity.id, false)}
                                                                    >
                                                                        <FontAwesomeIcon icon={faTimes} color={"white"}/>
                                                                        &nbsp;&nbsp;Rechazar
                                                                    </Button>
                                                                </>
                                                            }
                                                        </Col>
                                                    </Row>

                                                </td>

                                                {
                                                    !isSocialServiceUser && <>
                                                        <td>
                                                            <MoneyAmount amount={inscriptionOrderActivity.subtotalPrice}/>
                                                        </td>
                                                        <td>
                                                            <MoneyAmount amount={inscriptionOrderActivity.totalPrice}/>
                                                        </td>
                                                    </>
                                                }
                                            </tr>
                                        )
                                })
                            }

                            {
                                !isSocialServiceUser && <>

                                    <tr>
                                        <td className={"fw-bold"}>SUBTOTAL</td>
                                        <td>
                                            <MoneyAmount amount={subtotalPrice}/>
                                        </td>
                                        <td>
                                            <MoneyAmount amount={totalPriceWithAllServices}/>
                                        </td>
                                    </tr>

                                    {
                                        (
                                            (discountAmountOverPrice > 0 && discountType.value !== DISCOUNT_TYPE_ORDINARY_ID) ||
                                            inscription.inscriptionOrder.extraDiscount
                                        )
                                        &&
                                        <tr>
                                            <td>
                                                <span className={"fw-bold"}>DESCUENTOS</span><br/>
                                                <ul className={"mt-3"}>
                                                    {discountType && discountType.value !== DISCOUNT_TYPE_ORDINARY_ID &&
                                                        <li>{discountType.label}</li>}
                                                    {inscription.inscriptionOrder.extraDiscount?.value &&
                                                        <li>{inscription.inscriptionOrder.extraDiscount?.name}</li>}
                                                </ul>
                                            </td>
                                            <td className={"align-middle"}>
                                                -<MoneyAmount amount={discountAmountOverPrice}/>
                                            </td>
                                            <td className={"align-middle"}>
                                                -<MoneyAmount amount={discountAmountOverPrice}/>
                                            </td>
                                        </tr>
                                    }

                                    <tr>
                                        <td className={"fw-bold"}>TOTAL DE LA SOLICITUD</td>
                                        <td></td>
                                        <td style={{"fontWeight": "bold"}}>
                                            <MoneyAmount
                                                amount={discountAmountOverPrice ? totalPriceWithDiscount : totalPriceWithAllServices}
                                            />
                                        </td>
                                    </tr>

                                    {
                                        inscription.status.id === INSCRIPTION_PAID_STATUS_ID &&
                                        <>
                                            <tr>
                                                <td className={"fw-bold"}>PAGADO</td>
                                                <td>0 €</td>
                                            </tr>
                                        </>
                                    }

                                </>
                            }

                            </tbody>
                        </table>

                        <Row>
                            <Form.Group controlId="observations" className={"mt-4"}>
                                <Form.Label className={"fw-bold"}>
                                    Observaciones
                                </Form.Label>
                                <textarea
                                    className="mt-2 form-control"
                                    rows={5}
                                    onChange={(e: any) => setObservation(e.target.value)}
                                    value={observation}
                                ></textarea>

                                <Form.Label className={"mt-2"}>
                                    <Form.Check
                                        id={"sendObservationToTutor"}
                                        type="checkbox"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSendObservationToTutor(e.target.checked)}
                                        checked={sendObservationToTutor}
                                        label={"Enviar esta observación al tutor por email"}
                                    />
                                </Form.Label>
                            </Form.Group>
                        </Row>

                        <Row className={"my-4"}>
                            <Col className={"d-flex justify-content-end"}>
                                <Button
                                    className={"purple-btn"}
                                    onClick={createObservation}
                                    disabled={!observation}
                                >Añadir</Button>
                            </Col>
                        </Row>

                        <table className={"table table-bordered redTable mt-4"}>
                            <thead>
                            <tr>
                                <th>OBSERVACIONES</th>
                                <th>FECHA</th>
                                <th>AUTOR</th>
                                <th>ENVIADA AL TUTOR</th>
                            </tr>
                            </thead>
                            <tbody>

                            {
                                observations.length === 0 &&
                                <tr>
                                    <td colSpan={3}>No hay observaciones para esta solicitud.</td>
                                </tr>
                            }

                            {
                                observations.map((item: any, key: number) => {
                                    return (
                                        <tr key={key}>
                                            <td style={{"width":"45%"}}>{item.observation}</td>
                                            <td style={{"width": "20%"}}>{item.date}</td>
                                            <td style={{"width": "20%"}}>{item.createdBy.username}</td>
                                            <td style={{"width":"20%"}}>
                                                <Enabled enabled={item.isTutorNotified} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                            </tbody>
                        </table>

                    </Col>
                }

            </DefaultContainer>
        </RequireAuth>
    )
}
