import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {Badge, Button, Col, Form, Row} from "react-bootstrap";
import {RequireAuth} from "Components/Security/RequireAuth";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons/faEdit";
import {IsValidModal} from "Views/IndividualNeeds/IsValidModal";
import {ObservationsModal} from "Views/IndividualNeeds/ObservationsModal";
import {exportAdminInscriptionSpecialNeeds, searchAdminInscriptionSpecialNeeds} from "Api/Query/SpecialNeedQuery";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {AdminCrudList} from "Views/Shared/Crud/AdminCrudList";
import Select from "react-select";
import {SpecialNeedSelect} from "Components/SpecialNeed/SpecialNeedSelect";
import {UserObservationsModal} from "./UserObservationsModal";
import {useQuery} from "react-query";
import {appLoading} from "../../Store/loadingSlice";
import {Notificator} from "../../Services/Notificator/Notificator";
import {RefreshSpinIcon} from "../../Components/Icons/RefreshSpinIcon";
import {useAppDispatch} from "../../hooks";
import {faCheck, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {InscriptionStatusesSelect} from "../../Components/InscriptionOrder/InscriptionStatusSelect";
import {trimString} from "../../Services/String/StringUtils";
import {forceDownloadFromUrl} from "../../Services/File/FileDownloader";
import {LocalStorage} from "../../Services/LocalStorage/LocalStorage";

export const AdminIndividualNeedsList: React.FC = () => {

    const FORM_FILTER_SEARCH = 'admin.special_needs_list.form.search'
    const FORM_FILTER_VALIDATED = 'admin.special_needs_list.form.validated'
    const FORM_FILTER_TYPE = 'admin.special_needs_list.form.type'
    const FORM_FILTER_STATUS = 'admin.special_needs_list.form.status'
    const PAGE_FILTER = 'admin.special_needs_list.page'

    const localStorage = new LocalStorage()
    const generateSearchFiltersObject = (
        search: string,
        status: {label:string,value:string}|any,
        validated: number,
        type: {label:string,value:string}
    ): any => {
        const parsedFilters: any = {}

        if (search) {
            parsedFilters['q'] = search
        }

        if ([1, 0].includes(validated)) {
            parsedFilters['validated'] = validated
        }

        if (type?.value) {
            parsedFilters['type'] = type.value
        }

        if (status) {
            parsedFilters['inscriptionStatus'] = isNaN(status) ? parseInt(status.value) : status
        }

        return parsedFilters
    }

    // init stored filters
    const defaultSearchFilterValue = localStorage.get(FORM_FILTER_SEARCH) ?? ''
    const defaultStatusFilterValue = localStorage.get(FORM_FILTER_STATUS) ? JSON.parse(localStorage.get(FORM_FILTER_STATUS)) : null
    const defaultValidatedFilterValue = localStorage.get(FORM_FILTER_VALIDATED) ?? 0
    const defaultTypeFilterValue = localStorage.get(FORM_FILTER_TYPE) ? JSON.parse(localStorage.get(FORM_FILTER_TYPE)) : null

    const validatedFilterOptions = [
        {label: 'Todos', value: null},
        {label: 'Sí', value: 1},
        {label: 'No', value: 0},
    ]
    const dispatch = useAppDispatch()

    const page = useRef<number>(localStorage.get(PAGE_FILTER) ? parseInt(localStorage.get(PAGE_FILTER)) : 1)
    const [refreshSwitch, setRefreshSwitch] = useState<boolean>(false)
    const [showIsValidModal, setShowIsValidModal] = useState<boolean>(false)
    const [showObservationsModal, setShowObservationsModal] = useState<boolean>(false)
    const [showUserObservationsModal, setUserShowObservationsModal] = useState<boolean>(false)
    const [selectedItem, setSelectedItem] = useState<object | null>(null)
    const [customFiltersData, setCustomFiltersData] = useState<{ [key: string]: any }>(
        generateSearchFiltersObject(
            defaultSearchFilterValue,
            defaultStatusFilterValue,
            defaultValidatedFilterValue,
            defaultTypeFilterValue
        )
    )
    const [customFiltersDataToApply, setCustomFiltersDataToApply] = useState<{ [key: string]: any }>(
        generateSearchFiltersObject(
            defaultSearchFilterValue,
            defaultStatusFilterValue,
            defaultValidatedFilterValue,
            defaultTypeFilterValue
        )
    )
    const [validatedFilter, setValidatedFilter] = useState<any>(validatedFilterOptions[2])
    const [typeFilter, setTypeFilter] = useState<any>(defaultTypeFilterValue ?? null)
    const [statusFilter, setStatusFilter] = useState<any>(defaultStatusFilterValue ?? null)
    const [exporting, setExporting] = useState<boolean>(false)

    const applyFilterChanges = () => {
        let parsedFilters: any = generateSearchFiltersObject(
            customFiltersData.q,
            customFiltersData.inscriptionStatus,
            customFiltersData.validated,
            customFiltersData.type
        )
        page.current = 1
        parsedFilters = {...parsedFilters, page: page.current}
        setCustomFiltersDataToApply(parsedFilters)
    }

    const headers: string[] = [
        'NIÑO/A',
        'TUTOR/A',
        'CENTRO',
        'ACTIVIDADES',
        'INSCRIPCIÓN',
        'ARCHIVOS ADJUNTOS',
        'TIPO',
        'COMENTARIOS',
        'VALIDADO',
        'OBSERVACIONES',
        'OBSERVACIONES AL USUARIO',
    ]

    const editIsValidated = (item: object) => {
        setSelectedItem(item)
        setShowIsValidModal(true)
    }

    const editObservations = (item: object) => {
        setSelectedItem(item)
        setShowObservationsModal(true)
    }

    const editUserObservations = (item: object) => {
        setSelectedItem(item)
        setUserShowObservationsModal(true)
    }

    const closeEditIsValidated = () => {
        setSelectedItem(null)
        setShowIsValidModal(false)
    }

    const excelExport = () => {
        setExporting(true)
        exportQuery.refetch()
    }

    const exportQuery = useQuery({
        enabled: false,
        queryKey: ['exportAdminInscriptionSpecialNeeds'],
        queryFn: () => exportAdminInscriptionSpecialNeeds(customFiltersDataToApply),
        onSuccess: (response: any) => {
            const responseData = response.data
            forceDownloadFromUrl(responseData._result.url);
            setExporting(false)
            dispatch(appLoading({value: false, text: 'Cargando'}))
        },
        onError: () => {
            Notificator.error('Se ha producido un error inesperado.')
            setExporting(false)
            dispatch(appLoading({value: false, text: 'Cargando'}))
        }
    })

    useEffect(() => {
        localStorage.set(PAGE_FILTER, page.current)
    }, [page.current]);

    return (
        <RequireAuth>
            <DefaultContainer fluid={true}>

                <Col id={"mainRequestList"}>
                    <AdminCrudList
                        defaultPage={page.current}
                        onPageChange={(newPage:number) => {
                            page.current = newPage
                        }}
                        fluidContainer={true}
                        refreshSwitch={refreshSwitch}
                        canSelectMultipleRows={false}
                        title={"Listado de necesidades especiales"}
                        headers={headers}
                        queryMethod={searchAdminInscriptionSpecialNeeds}
                        queryName={"searchAdminInscriptionSpecialNeeds"}
                        deleteMethod={() => console.log(1)}
                        customFiltersData={customFiltersDataToApply}
                        exportChildren={
                            <Button
                                className={"purple-btn mx-4"}
                                onClick={excelExport}
                                disabled={exporting}>
                                {exporting && <><RefreshSpinIcon/>&nbsp;&nbsp;</>}
                                <>Exportar</>
                            </Button>
                        }
                        customFilters={
                            <>
                                <Col xs={12} sm={3}>
                                    <Form.Group controlId={"queryFilter"}>
                                        <Form.Label>
                                            Buscar
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            onChange={(e: any) => {
                                                const value = e.target.value ?? ''
                                                setCustomFiltersData({...customFiltersData, q: value})
                                                localStorage.set(FORM_FILTER_SEARCH, value)
                                            }}
                                            value={customFiltersData.q ?? ''}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={3}>
                                    <Form.Group controlId={"queryFilter"}>
                                        <Form.Label>
                                            Validado
                                        </Form.Label>
                                        <Select
                                            placeholder={"Selecciona una opción"}
                                            options={validatedFilterOptions}
                                            value={validatedFilter}
                                            onChange={(e: any) => {
                                                setValidatedFilter(e)

                                                if (e.value !== null) {
                                                    setCustomFiltersData({...customFiltersData, validated: e.value})
                                                    localStorage.set(FORM_FILTER_VALIDATED, e.value)
                                                } else {
                                                    let newFilters: any = {...customFiltersData}
                                                    delete newFilters.validated;
                                                    setCustomFiltersData(newFilters)
                                                    localStorage.set(FORM_FILTER_VALIDATED, 0)
                                                }
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={3}>
                                    <Form.Group controlId={"queryFilter"}>
                                        <Form.Label>
                                            Tipo
                                        </Form.Label>
                                        <SpecialNeedSelect
                                            onChange={(option: any) => {
                                                setTypeFilter(option)
                                                localStorage.set(FORM_FILTER_TYPE, JSON.stringify(option))

                                                if (option && option.value) {
                                                    setCustomFiltersData({...customFiltersData, type: option.value})
                                                } else {
                                                    let newFilters: any = {...customFiltersData}
                                                    delete newFilters.type;
                                                    setCustomFiltersData(newFilters)
                                                }
                                            }}
                                            value={typeFilter}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={3}>
                                    <Form.Group controlId={"queryFilter"}>
                                        <Form.Label>
                                            Estado de la inscripción
                                        </Form.Label>
                                        <InscriptionStatusesSelect
                                            value={statusFilter}
                                            onChange={(option: any) => {
                                                setStatusFilter(option)
                                                localStorage.set(FORM_FILTER_STATUS, JSON.stringify(option))

                                                if (option && option.value) {
                                                    setCustomFiltersData({...customFiltersData, inscriptionStatus: option.value})
                                                } else {
                                                    let newFilters: any = {...customFiltersData}
                                                    delete newFilters.inscriptionStatus;
                                                    setCustomFiltersData(newFilters)
                                                }
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Row className={"mt-2"}>
                                    <Col>
                                        <Button
                                            className={"purple-btn"}
                                            onClick={applyFilterChanges}
                                        >Buscar</Button>
                                        &nbsp;
                                        <Button
                                            variant={"secondary"}
                                            onClick={() => {
                                                page.current = 1
                                                setValidatedFilter(null)
                                                setTypeFilter(null)
                                                setCustomFiltersData({})
                                                setCustomFiltersDataToApply({})
                                                localStorage.set(PAGE_FILTER, page.current)
                                                localStorage.set(FORM_FILTER_SEARCH, '')
                                                localStorage.set(FORM_FILTER_STATUS, null)
                                                localStorage.set(FORM_FILTER_VALIDATED, 0)
                                                localStorage.set(FORM_FILTER_TYPE, null)
                                            }
                                            }
                                        >Limpiar filtros</Button>
                                    </Col>
                                </Row>
                            </>
                        }
                        tableRow={(item: any, key: number) =>
                            <>
                                <td>
                                    <Link
                                        to={ROUTE_PATHS.ADMIN_KIDS_EDIT.replace(":id", item.child.id)}
                                    >
                                        {item.child.name}
                                    </Link>
                                </td>
                                <td>
                                    <p>{item.tutor.name}</p>
                                    <p>Telf: {item.tutor.phoneNumbers}</p>
                                </td>
                                <td>{item.center.name}</td>
                                <td>
                                    {
                                        item.activities.map((act: any, k: number) => {
                                            return (<>{act.name}<br/></>)
                                        })
                                    }
                                </td>
                                <td>
                                    {
                                        item.inscription && <>
                                            <Link
                                                target={"_blank"}
                                                to={ROUTE_PATHS.ADMIN_INSCRIPTION_DETAIL.replace(":id", item.inscription.id)}>{item.inscription.id}</Link>
                                            <br/>
                                            <Badge
                                                bg={item.inscription?.status?.color}>{item.inscription?.status?.name.toUpperCase()}</Badge>
                                        </>
                                    }
                                </td>
                                <td>
                                    <ul style={{paddingLeft: 0}}>
                                        {item.specialNeed.attachments?.map((attachment: any, key: number) => {
                                            return (
                                                <li key={key} style={{listStyle: "none", marginLeft: 0, paddingLeft: 0}}>
                                                    <a
                                                        href={attachment.url}
                                                        target={"_blank"}
                                                        title={attachment.name}
                                                    >
                                                        {
                                                            attachment.deletedAt &&
                                                                <Badge bg={"danger"}>
                                                                <FontAwesomeIcon icon={faTrashCan}/>&nbsp;&nbsp;
                                                                {attachment.deletedAt} - {trimString(attachment.name, 15)}
                                                                </Badge>
                                                        }
                                                        {
                                                            !attachment.deletedAt &&
                                                            <Badge bg={"success"}>
                                                                <FontAwesomeIcon icon={faCheck} />&nbsp;&nbsp;
                                                                {trimString(attachment.name, 15)}
                                                            </Badge>
                                                        }
                                                    </a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </td>
                                <td>{item.specialNeed.name}</td>
                                <td>
                                    {item.specialNeed.comments}
                                </td>
                                <td>
                                    {item.isValidated ? <>Sí</> : <>No</>}
                                    &nbsp;&nbsp;
                                    <FontAwesomeIcon
                                        icon={faEdit}
                                        className="pointer"
                                        onClick={() => editIsValidated(item)}
                                    />
                                </td>
                                <td>
                                    {item.observations}
                                    &nbsp;&nbsp;
                                    <FontAwesomeIcon
                                        icon={faEdit}
                                        className="pointer"
                                        onClick={() => editObservations(item)}
                                    />
                                </td>
                                <td>
                                    {item.userObservations}
                                    &nbsp;&nbsp;
                                    <FontAwesomeIcon
                                        icon={faEdit}
                                        className="pointer"
                                        onClick={() => editUserObservations(item)}
                                    />
                                </td>
                            </>
                        }

                    />

                </Col>

            </DefaultContainer>

            <IsValidModal
                show={showIsValidModal}
                onClose={closeEditIsValidated}
                item={selectedItem}
                onUpdated={() => setRefreshSwitch(!refreshSwitch)}
            />
            <ObservationsModal
                show={showObservationsModal}
                onClose={() => setShowObservationsModal(false)}
                item={selectedItem}
                onUpdated={() => setRefreshSwitch(!refreshSwitch)}
            />
            <UserObservationsModal
                show={showUserObservationsModal}
                onClose={() => setUserShowObservationsModal(false)}
                item={selectedItem}
                onUpdated={() => setRefreshSwitch(!refreshSwitch)}
            />

        </RequireAuth>
    )
}
